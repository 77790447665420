import * as React from 'react'
import { Action, State, messagesReducer } from './reducer'

export type LevelCallout = 'alert' | 'error' | 'info' | 'notification' | 'success'
export type LevelToast = 'alert' | 'error' | 'info' | 'success' | 'neutral'
export type LevelFlash = LevelCallout

export type MessageBase = {
  context: string
  deleted?: boolean
  forceClose?: boolean
  message: string
  onClose?: () => void
  onPrimaryAction?: () => void
  onSecondaryAction?: () => void
  primaryAction?: string
  secondaryAction?: string
  timeOpen?: number
  zIndexLevel?: 'high' | 'low'
}

export type MessageCallout = MessageBase & {
  level: LevelCallout
  type: 'callout'
}

export type MessageToast = MessageBase & {
  level: LevelToast
  type: 'toast'
}

export type MessageFlash = MessageBase & {
  message: string
  level: LevelFlash
  type: 'flash'
}

export type Message = MessageFlash | MessageToast | MessageCallout

type Value = {
  state: State
  dispatch: React.Dispatch<Action>
}

const messagesContext = React.createContext<Value | undefined>(undefined)

function MessagesProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(messagesReducer, {})
  const value = { state, dispatch }
  return <messagesContext.Provider value={value}>{children}</messagesContext.Provider>
}

function useMessagesContext() {
  const context = React.useContext(messagesContext)
  if (context === undefined) {
    throw new Error('useMessagesContext must be used within a messagesProvider')
  }
  return context
}

export { MessagesProvider, useMessagesContext }
